import { template as template_968c2eb301524342aec8c06f20467bba } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_968c2eb301524342aec8c06f20467bba(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
