import { template as template_6646a8e5184a40688330c0bd10b30668 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6646a8e5184a40688330c0bd10b30668(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
