import { template as template_60a9c7f16bec40ceb86c800e779ce51a } from "@ember/template-compiler";
const FKLabel = template_60a9c7f16bec40ceb86c800e779ce51a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
